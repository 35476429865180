import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { italianDistricts } from '../../../constants';
import { required } from 'helpers/validators';
import { IOption, IWarehouse } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { laboratoryService } from 'services/api';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';

interface LaboratoryFormParams {
  warehouse: IWarehouse;
  onSubmit: any;
}
function WarehouseForm(params: LaboratoryFormParams) {
  const { t } = useTranslation();

  const [laboratories, setLaboratories] = useState<IOption[]>([]);

  useEffect(() => {
    laboratoryService.getAllBaseInformation().then(setLaboratories);
  }, []);

  const { warehouse, onSubmit } = params;
  return (
    <div>
      {warehouse && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!warehouse.id}>
          <div className="flex flex-wrap">
            <div className="w-full mb-4 md:w-1/3 pr-4">
              <IdentityField
                name="code"
                initialValue={warehouse.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full mb-4 md:w-2/3">
              <IdentityField
                name="description"
                initialValue={warehouse.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <IdentityField
                name="city"
                initialValue={warehouse.city}
                component={TextFieldAdapter}
                label={t('entities.warehouse.city')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4 pr-4">
              <IdentityField
                name="district"
                component={AutocompleteAdapter}
                initValue={italianDistricts.find((s) => s.code === warehouse.district)}
                options={italianDistricts}
                optionValue={'code'}
                label={t('entities.warehouse.district')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4">
              <IdentityField
                name="zip_code"
                initialValue={warehouse.zip_code}
                component={TextFieldAdapter}
                label={t('entities.warehouse.zip_code')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <IdentityField
                name="address"
                initialValue={warehouse.address}
                component={TextFieldAdapter}
                label={t('entities.warehouse.address')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4 pr-4">
              <IdentityField
                name="address_2"
                initialValue={warehouse.address_2}
                component={TextFieldAdapter}
                label={t('entities.warehouse.address_2')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4">
              <IdentityField
                name="phone_number"
                initialValue={warehouse.phone_number}
                component={TextFieldAdapter}
                label={t('entities.warehouse.phone_number')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/3 pr-4">
              <IdentityField
                name="order_prefix"
                initialValue={warehouse.order_prefix}
                component={TextFieldAdapter}
                label={t('entities.warehouse.order_prefix')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/3 pr-4">
              <IdentityField
                name="additional_details"
                initialValue={warehouse.additional_details}
                component={TextFieldAdapter}
                label={t('global.additional_details')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/3">
              <IdentityField
                name="opening_hours"
                initialValue={warehouse.opening_hours}
                component={TextFieldAdapter}
                label={t('global.opening_hours')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <IdentityField
                name="contact"
                initialValue={warehouse.contact}
                component={TextFieldAdapter}
                label={t('global.contact')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2">
              <IdentityField
                name="contact_2"
                initialValue={warehouse.contact_2}
                component={TextFieldAdapter}
                label={t('global.contact_2')}
                type="text"
              />
            </div>
            <div className="w-full mb-4">
              <IdentityField
                name="order_confirmation_email"
                initialValue={warehouse.order_confirmation_email}
                component={TextFieldAdapter}
                label={t('entities.warehouse.order_confirmation_email')}
                placeholder="mario.verdi@mail.it; francesco.bianchi@mail.it; franco.rossi@mail.it"
                type="text"
              />
            </div>
            <div className="w-full mb-4">
              <IdentityField
                name="cc"
                initialValue={warehouse.cc}
                component={TextFieldAdapter}
                label={t('entities.warehouse.cc')}
                placeholder="mario.verdi@mail.it; francesco.bianchi@mail.it; franco.rossi@mail.it"
                type="text"
              />
            </div>
            <div className="w-full mb-4">
              <IdentityField
                name="notes"
                initialValue={warehouse.notes}
                component={TextFieldAdapter}
                label={t('global.notes')}
                type="text"
              />
            </div>
            <div className="flex flex-row gap-5 w-full">
              <div className="w-2/3">
                {laboratories && (
                  <IdentityField
                    name="laboratories"
                    component={MultipleAutocompleteAdapter}
                    options={laboratories}
                    initValue={warehouse.laboratories}
                    label={t('global.laboratories')}
                    type="text"
                  />
                )}
              </div>
              <div className="flex justify-center items-center w-1/3">
                <IdentityField
                  name="fiscal"
                  initialValue={warehouse.fiscal}
                  component={CheckboxAdapter}
                  label={t('global.fiscal')}
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default WarehouseForm;
