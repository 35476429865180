import {
  GridRenderCellParams,
  GridColumns,
  GridValueFormatterParams,
  ValueOptions,
  GridCellParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import {
  dateFilterOperators,
  filterOperators,
  numberFilterOperators,
  singleSelectFilterOperators
} from '../../../../constants';
import { currencyFormatter } from 'helpers/currency-formatter';
import { currencyCell } from '../cells/currency-cell';
import { dateCell } from '../cells/date-cell';

export function OrderDetailColumns(): GridColumns {
  const { t } = useTranslation();
  const translationPrefix = 'entities.order_line';

  const statusOptions: ValueOptions[] = [
    { value: 'PENDING', label: t('entities.order_line.PENDING') },
    { value: 'COMPLETED', label: t('entities.order_line.COMPLETED') },
    { value: 'CANCELED', label: t('entities.order_line.CANCELED') }
  ];

  const statusColor = (statusString: string) => {
    switch (statusString) {
      case 'PENDING':
        return 'text-orange-500';
      case 'COMPLETED':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const strikedThroughRenderCell = (params: GridCellParams) =>
    params.row.status === 'CANCELED' && 'line-through';

  const columns: GridColumns = [
    // {
    //   field: 'id',
    //   headerName: t(`global.id`),
    //   width: 150,
    //   type: 'number',
    //   filterable: false,
    //   renderCell: baseCell
    // },
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 150,
      filterOperators,
      valueGetter: (params) => params.row.product.code,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'product_description',
      headerName: t(`global.product_description`),
      width: 250,
      filterOperators,
      valueGetter: (params) => params.row.product.description,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 250,
      filterOperators,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },

    {
      field: 'is_periodic',
      headerName: t(`entities.order_line.is_periodic`),
      width: 100,
      type: 'boolean',
      renderCell: booleanCell(t),
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'warehouse',
      headerName: t('global.warehouse'),
      width: 200,
      filterable: false,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'ref',
      headerName: t(`entities.order_line.ref`),
      width: 100,
      filterOperators,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'order_date',
      headerName: t(`entities.movement.order_date`),
      width: 100,
      filterOperators: dateFilterOperators,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'requested_shipment_date',
      type: 'date',
      headerName: t(`entities.order_line.requested_shipment_date`),
      width: 150,
      renderCell: dateCell,
      cellClassName: strikedThroughRenderCell
    },

    {
      field: 'remaining_quantity',
      type: 'number',
      headerName: t('entities.order_line.remaining_quantity'),
      width: 150,
      filterable: false,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    },
    {
      field: 'status',
      headerName: t('global.status'),
      width: 100,
      type: 'singleSelect',
      // valueOptions: statusOptions,
      // filterOperators: singleSelectFilterOperators,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={statusColor(value)}>{t(`entities.order_line.${value}`)}</span>
          </div>
        );
      }
    },
    {
      field: 'notes',
      headerName: t('global.notes'),
      width: 300,
      filterOperators,
      renderCell: baseCell,
      cellClassName: strikedThroughRenderCell
    }
  ];

  ['unit_price', 'pending_amount', 'amount'].forEach((field) => {
    columns.push({
      field: field,
      width: 150,
      headerName: t(`${translationPrefix}.${field}`),
      editable: false,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3),
      filterOperators: numberFilterOperators
    });
  });

  return columns;
}
