import { GridSelectionModel } from '@mui/x-data-grid-premium';
import toast from 'features/toast';
import { ITransportDocument } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class TransportDocumentService extends BaseService<ITransportDocument> {
  constructor() {
    super('transport-documents');
  }
  canDelete = true;
  canBulkDelete = true;

  async getAllBaseInformation(params: Record<string, unknown> = {}) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/`, { params });
      return res.data.results;
    } catch (e) {
      toast.error(e.message);
    }
  }

  async getOnlyBaseFields(limit = 5, offset = 0, params: Record<string, unknown> = {}) {
    // Make the request and return the data if params exist
    const res = await axiosPrivate.get(`${this.modulePrefix}/basic-info/`, {
      params: {
        limit,
        offset,
        ...params
      }
    });
    return res.data;
  }

  download = async (endpoint: string, transportDocumentId: number) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${transportDocumentId}/${endpoint}/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  attachLines = async (transportDocumentId: number) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${transportDocumentId}/attach-lines/`
    );
    return res.data;
  };

  recalculateAmount = async (transportDocumentId: number) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/recalculate-ammount/`, {
      params: { ddtID: transportDocumentId }
    });
    return res.data.results;
  };

  attachUnapprovedLines = async (transportDocumentId: number, movements: GridSelectionModel) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${transportDocumentId}/attach-unapproved-lines/`,
      {
        movements
      }
    );
    return res.data;
  };

  getUnassigned = async (limit = 25, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/unassigned/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const transportDocumentService = new TransportDocumentService();
