import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { useTranslation } from 'react-i18next';
import { filterOperators } from '../../../../constants';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Tooltip } from '@mui/material';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import { currencyCell } from '../cells/currency-cell';

const statusColor = (statusString: string) => {
  switch (statusString) {
    case 'PENDING':
      return 'text-red-500';
    case 'COMPLETED':
      return 'text-green-500';
    case 'NEW':
      return 'text-yellow-500';
    default:
      return 'text-orange-500';
  }
};

export function OrderLinesColumns(handleDelete?: any): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'index',
      headerName: t('global.count'),
      width: 50,
      filterable: false,
      editable: true,
      sortable: false
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      // type: 'singleSelect',
      // valueOptions: statusOptions.map((type) => type.display_name),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={statusColor(value)}>{t(`pages.purchase-orders.status.${value}`)}</span>
          </div>
        );
      }
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 200,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.value['code']} />
      )
    },
    {
      field: 'description',
      headerName: t(`entities.product.description`),
      width: 300,
      filterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.row.product.description} />
      )
    },
    {
      field: 'initial_quantity',
      headerName: t(`global.quantity`),
      width: 150,
      type: 'number',
      editable: handleDelete !== false,
      renderCell: baseCell
    },
    {
      field: 'remaining_quantity',
      headerName: t(`entities.order_line.remaining_quantity`),
      width: 150,
      type: 'number',
      renderCell: baseCell
    },
    {
      field: 'discounted',
      headerName: t('entities.movement.discounted'),
      width: 100,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'amount',
      headerName: t('entities.order_line.amount'),
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'iva',
      headerName: t('entities.order_line.iva'),
      width: 75,
      renderCell: baseCell
    },
    {
      field: 'requested_shipment_date',
      headerName: t(`entities.purchase_request.requested_shipment_date`),
      width: 200,
      filterOperators,
      type: 'date',
      editable: handleDelete !== false,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`entities.purchase_request.notes`),
      width: 200,
      editable: handleDelete !== false,
      renderCell: baseCell
    }
  ];
  if (handleDelete) {
    columns.push({
      field: 'actions',
      type: 'actions',
      width: 150,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={1}
          icon={
            <Tooltip title={t('actions.delete')} placement="top">
              <BackspaceIcon />
            </Tooltip>
          }
          onClick={() => handleDelete(params.row)}
          label="remove"
        />
      ]
    });
  }
  return columns;
}
