import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { loadingActions } from 'features';
import { required } from 'helpers/validators';
import { IContract, IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { laboratoryService, productService, supplierService, warehouseService } from 'services/api';
import { useAppSelector } from 'app/store';

interface ContractHeaderFormParams {
  contract: IContract;
  onSubmit: any;
  onProductsUpdate: any;
  onWarehousesUpdate: any;
}
function ContractHeaderForm(params: ContractHeaderFormParams) {
  const { contract, onSubmit, onProductsUpdate, onWarehousesUpdate } = params;
  const { t } = useTranslation();
  const sap_active = useAppSelector((state) => state.auth.sap_active);

  const dispatch = useDispatch();

  const [suppliers, setSuppliers] = useState<IOption[]>([]);
  const [laboratories, setLaboratories] = useState<IOption[]>([]);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    // Load suppliers and laboratories
    productService.getAllBaseInformation().then((res) => onProductsUpdate(res));
    warehouseService.getAllBaseInformation().then(onWarehousesUpdate);
    supplierService.getAllBaseInformation().then((res) => {
      setSuppliers(
        res.map((value) => {
          return {
            id: value.id,
            code: `${value.code} - ${value.company_name}`
          };
        })
      );
    });
    laboratoryService.getAllBaseInformation().then(setLaboratories);
    dispatch(loadingActions.stopLoading());
  }, []);

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} hideControls={true} formId="contractForm">
        <div className="flex flex-wrap mt-4">
          {sap_active && (
            <div className="w-full mb-4">
              <IdentityField
                name="sync_with_sap"
                initialValue={contract.sync_with_sap}
                component={CheckboxAdapter}
                label={t('entities.sap.sync_with_sap')}
                placeholder={t('entities.sap.sync_with_sap')}
                type="checkbox"
              />
            </div>
          )}
          <div className="w-6/12 mb-4 pr-4">
            <IdentityField
              name="code"
              initialValue={contract.code}
              component={TextFieldAdapter}
              label={t('global.code')}
              placeholder={t('global.code')}
              type="text"
              validate={required}
            />
          </div>
          <div className="w-1/6 mb-4 pr-4">
            <IdentityField
              name="drafting_date"
              initialValue={contract.drafting_date}
              component={DatePickerAdapter}
              label={t('global.drafting_date')}
              type="date"
            />
          </div>
          <div className="w-1/6 mb-4 pr-4">
            <IdentityField
              name="expiration_date"
              initialValue={contract.expiration_date}
              component={DatePickerAdapter}
              label={t('global.expiration')}
              placeholder={t('global.expiration')}
              type="date"
            />
          </div>
          <div className="w-1/6 mb-4">
            <IdentityField
              name="active"
              initialValue={contract.active}
              component={CheckboxAdapter}
              label={t('global.active')}
              placeholder={t('global.active')}
              type="checkbox"
            />
          </div>
          <div className="w-1/2 mb-4 pr-4">
            <IdentityField
              name="tender_ref"
              initialValue={contract.tender_ref}
              component={TextFieldAdapter}
              label={t('global.tender')}
              type="text"
            />
          </div>
          <div className="w-1/2 mb-4">
            <IdentityField
              name="commission_ref"
              initialValue={contract.commission_ref}
              component={TextFieldAdapter}
              label={t('global.commission')}
              type="text"
            />
          </div>
          <div className="w-1/2 mb-4 pr-4">
            <IdentityField
              name="order_email"
              initialValue={contract.order_email}
              component={TextFieldAdapter}
              label={t('global.order_email')}
              type="email"
            />
          </div>
          <div className="w-1/2 mb-4">
            <IdentityField
              name="ticketing_email"
              initialValue={contract.ticketing_email}
              component={TextFieldAdapter}
              label={t('global.ticketing_email')}
              type="email"
            />
          </div>
          <div className="w-full mb-4">
            <IdentityField
              name="note"
              initialValue={contract.note}
              component={TextFieldAdapter}
              label={t('global.notes')}
              type="text"
            />
          </div>
          <div className="w-1/2 mb-4 pr-4">
            {suppliers && (
              <IdentityField
                name="supplier"
                component={AutocompleteAdapter}
                initValue={
                  contract.supplier
                    ? {
                        id: contract.supplier.id,
                        code: `${contract.supplier.code} - ${contract.supplier['company_name']}`
                      }
                    : null
                }
                defaultValue={contract.supplier.id}
                options={suppliers}
                label={t('global.supplier')}
                type="text"
              />
            )}
          </div>

          <div className="w-1/2 mb-4">
            {laboratories && (
              <IdentityField
                name="laboratories"
                component={MultipleAutocompleteAdapter}
                options={laboratories}
                initValue={contract.laboratories}
                label={t('global.laboratories')}
                type="text"
              />
            )}
          </div>
          <div className="p-2 w-full md:w-1/2">
            <IdentityField
              name="maintenance_max_compliance_days"
              label={t(`entities.contract.maintenance_max_compliance_days`)}
              initialValue={contract.maintenance_max_compliance_days}
              component={TextFieldAdapter}
              type="number"
            />
          </div>
          <div className="p-2 w-full md:w-1/2">
            <IdentityField
              name="ticketing_max_compliance_hours"
              label={t(`entities.contract.ticketing_max_compliance_hours`)}
              initialValue={contract.ticketing_max_compliance_hours}
              component={TextFieldAdapter}
              type="number"
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default ContractHeaderForm;
