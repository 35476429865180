import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { composeValidators, mustBeNumber } from 'helpers/validators';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect } from 'react';

// Interface to define the parameters for the UpdateContractLineDialog component
interface UpdateContractLineDialogFormParams {
  contractLine: any; // Represents the contract line data
  warehouses: IOption[]; // List of warehouse options for the dropdown
  onSubmit: any; // Callback function to handle form submission
  contract: string; // Contract code for dialog
}

// Function component to update contract line details
function UpdateContractLineDialog(params: UpdateContractLineDialogFormParams) {
  const { t } = useTranslation();
  const { contractLine, warehouses, onSubmit, contract } = params;

  // Translation prefix for easier access to translation keys
  const translationPrefix = 'pages.contract-line.edit';

  // Function to handle form submission
  const onSubmitContractLine = async (values) => {
    // Initialize an array to hold warehouse IDs
    const ids: number[] = [];
    // Loop through selected warehouses and map them to their IDs
    for (let i = 0; i < values.warehouses.length; i++) {
      ids.push(warehouses.find((war) => war.code == values.warehouses[i]).id);
    }

    // Prepare parameters for the submit function
    const params = {
      id: contractLine.id,
      unit_price: values.unit_price.replace(',', '.'), // Replace comma with dot for unit price
      initial_available_units: values.initial_available_units,
      initial_discounted_units: values.initial_discounted_units,
      iva: values.iva,
      warehouses: ids // Set warehouse IDs
    };

    // Call the onSubmit callback with contract line ID and prepared parameters
    onSubmit(contractLine.id, params);
  };

  return (
    <div>
      {/* Display contract line product information */}
      <div className="align-top p-3">
        {t(`${translationPrefix}.body`, { product: contractLine.product.code, contract: contract })}
      </div>
      {/* Form component to handle updating contract line */}
      <ReactFinalForm onSubmit={onSubmitContractLine} formId="updateContracLine" hideControls>
        <div className="flex flex-wrap">
          {/* Display product code as a disabled text field */}
          <div className="w-1/2 mb-4 pr-4">
            <IdentityField
              disabled
              component={TextFieldAdapter}
              id="outlined-disabled"
              name="product"
              label={t('global.product')}
              type="text"
              defaultValue={contractLine.product.code}
            />
          </div>
          {/* Multi-select autocomplete field for selecting warehouses */}
          <div className="w-1/2 mb-4">
            <IdentityField
              name="warehouses"
              component={MultipleAutocompleteAdapter}
              options={warehouses}
              optionValue="code"
              label={t('global.warehouses')}
              type="text"
              initValue={contractLine.warehouses}
              defaultValue={contractLine.warehouses}
            />
          </div>
          {/* Field to update unit price with validation */}
          <div className="w-1/4 mb-4 pr-4 flex">
            <IdentityField
              name="unit_price"
              label={t('entities.contract_line.unit_price')}
              component={TextFieldAdapter}
              type="number"
              validate={composeValidators(mustBeNumber)}
              defaultValue={contractLine.unit_price}
            />
          </div>
          {/* Field to update initial available units with validation */}
          <div className="w-1/4 mb-2 pr-2 flex">
            <IdentityField
              name="initial_available_units"
              label={t('entities.contract_line.initial_available_units')}
              component={TextFieldAdapter}
              type="number"
              validate={composeValidators(mustBeNumber)}
              defaultValue={contractLine.initial_available_units}
            />
          </div>
          {/* Field to update initial discounted units with validation */}
          <div className="w-1/4 mb-2 pr-2 flex">
            <IdentityField
              name="initial_discounted_units"
              label={t('entities.contract_line.initial_discounted_units')}
              component={TextFieldAdapter}
              type="number"
              validate={composeValidators(mustBeNumber)}
              defaultValue={contractLine.initial_discounted_units}
            />
          </div>
          {/* Field to update initial discounted units with validation */}
          <div className="w-1/4 mb-2 pr-2 flex">
            <IdentityField
              name="iva"
              label={t('entities.contract_line.iva')}
              component={TextFieldAdapter}
              type="number"
              validate={composeValidators(mustBeNumber)}
              defaultValue={contractLine.iva}
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default UpdateContractLineDialog;
