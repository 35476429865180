import { Tab, Tabs } from '@mui/material';
import { TenantPage } from 'components/common';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { IMetbaseDashboard } from 'interfaces';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { metabaseDashboardService } from 'services/api';

interface Props {
  category?: string;
}

function ReportList(props: Props) {
  const { category } = props;
  const [dashboards, setDashboards] = useState<IMetbaseDashboard[]>([]);
  const [value, setValue] = useState<number | string>();
  const navigate = useNavigate();

  useEffect(() => {
    metabaseDashboardService
      .getAllPaginated(100, 0, { category: category ?? 'DEFAULT_CONTEXT' })
      .then(setDashboards);
  }, [category]);

  useEffect(() => {
    if (value) {
      navigate(`${value}`);
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(+newValue);
  };
  return (
    <TenantPage title="" subtitle="">
      <Tabs
        sx={{ overflow: 'scroll', maxWidth: 'calc(100vw - 300px)' }}
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        aria-label="secondary tabs example">
        {dashboards.map((dashboard) => (
          <Tab key={dashboard.id} value={dashboard.id} label={dashboard.title}></Tab>
        ))}
      </Tabs>
      <Outlet />
    </TenantPage>
  );
}

export default ReportList;
